import { RestService } from './rest.service';
import { Injectable } from '@angular/core';
import { Connector } from '../models/connector.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './utils/config.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectorServiceService extends RestService<Connector> {

  constructor(configService: ConfigService, http: HttpClient) {
    super('/connector', http, configService);
  }
  getconnector(data) {
    return this.makeRequest('get', '/connector/connector', data)
  }

  enableDisableConnector(data) {
    return this.makeRequest('post', '/connector/enable-disable-connector', data)
  }
  createCompanyConnector(data) {
    return this.makeRequest('post', '/connector/connector', data)
  }

  updateCompanyConnector(data) {
    return this.makeRequest('put', '/connector/connector', data)
  }

  getkays(data) {
    return this.makeRequest('get', '/connector/mapping-keys', data)
  }

  getConnectorRoles(data) {
    return this.makeRequest('get', '/connector/fetch-criteria', data)
  }

  getConnectorRolesForAWS(data) {
    return this.makeRequest('get', '/connector/fetch-resource-data', data)
  }

  validateCredentials(data) {
    return this.makeRequest('post', '/connector/validate-credentials', data)
  }
}
