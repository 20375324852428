<div fxLayout="row">
    <div fxFlex="100" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center">
            <h2> {{pageType == 'edit'? 'Edit a Resource' :'Add a Resource'}}</h2>
        </div>
    </div>
    <div fxLayoutAlign="end center">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content>
    <form [formGroup]="connectorForm" class="example-form" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field fxFlex="grow" appearance="outline">
            <mat-label>Select a Resource</mat-label>
            <mat-select [(ngModel)]="connector.resourceType" name="resourceType" (selectionChange)="onSelectionChange($event)"
                [ngModelOptions]="{standalone: true}" disableOptionCentering panelClass="panelSelect">
                <mat-option [value]="item.value" *ngFor="let item of selectcustom">
                    {{ item.type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="pageType == 'edit'">
            <strong>
                The {{(connector.resourceType || '') }} connector enables you to gain visibility into users and their permissions inside your
                {{(connector.resourceType || '') }} tenant.

            </strong>

        </div>
        <div fxLayout="column" *ngIf="connector.resourceType && connector.resourceType == 'custom-connector'">
            <strong>
                Connect your custom app with YouAttest to automatically import Users and roles data.
            </strong>
            <a href="../../../../assets/API For Middleware Audit Creation.pdf" download target="_blank" mat-button
                color="primary">Learn How To Connect Your Customer App </a>
        </div>
        <div fxLayout="column" fxLayoutGap="15px" *ngIf="connector.resourceType && connector.resourceType != 'custom-connector'">
            <mat-form-field fxFlex="grow" appearance="outline">
                <mat-label>Resource Name</mat-label>
                <input matInput [(ngModel)]="connector.name" formControlName="name">
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('name')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
            <h3 *ngIf="pageType == 'add'"> Resource Settings</h3>
            <div *ngIf="connector.resourceType == 'salesforce'" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
                <strong>Authorization Grant Type</strong>
                <mat-radio-group aria-label="Select an option" fxLayout="row" [(ngModel)]="selectedOption"
                    name="selectedOption" [ngModelOptions]="{standalone: true}" fxLayoutAlign="space-around center"
                    (change)="onRadioChange($event)">
                    <mat-radio-button value="Secret" selected>client_credentials </mat-radio-button> &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <mat-radio-button value="password">password</mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field *ngIf="connector.resourceType == 'salesforce'" fxFlex="grow" appearance="outline">
                <mat-label>URL</mat-label>
                <input matInput [(ngModel)]="connector.apiUrl" formControlName="apiUrl">
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('apiUrl')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="connector.resourceType == 'AWS'" fxFlex="grow" appearance="outline">
                <mat-label>Region Name</mat-label>
                <input matInput [(ngModel)]="connector.regionName" formControlName="regionName">
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('regionName')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="grow" appearance="outline">
                <mat-label>Client Id</mat-label>
                <input matInput [(ngModel)]="connector.clientId" formControlName="clientId">
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('clientId')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
            <!-- *ngIf="!showPass" -->
            <mat-form-field fxFlex="grow" appearance="outline">
                <mat-label>Client Secret</mat-label>
                <input matInput [(ngModel)]="connector.clientSecret" formControlName="clientSecret">
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('clientSecret')['errorMessage'] }}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="showPass" fxFlex="grow" appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput [(ngModel)]="connector.username" formControlName="username">
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('username')['errorMessage'] }}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="showPass" fxFlex="grow" appearance="outline">
                <mat-label class="text-muted">Password</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="connector.password"
                    formControlName="password">

                <button mat-icon-button matSuffix (click)="hide = !hide" type="button" class="text-muted">
                    <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                </button>
                <mat-error *ngIf="isSubmitted">
                    {{ connectorForm.get('password')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
        </div>

        <div fxFlex="grow" *ngIf="pageType=='edit'">
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="center center">
                <h3>Field Mapping</h3>
            </div>
            <div fxLayout="column" fxLayoutAlign="center stretch">
                <div fxLayout="row" fxLayoutAlign="center stretch">
                    <div fxFlex="40">
                        <h4>YouAttest Fields</h4>
                    </div>
                    <div fxFlex="50">
                        <h4>{{(connector.resourceType || '') }} Fields</h4>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center stretch"
                    *ngFor="let item of mappingKeys?.youattest_keys; let i = index ">
                    <div fxFlex="40" fxLayoutAlign="start center">
                        <strong>{{item| uppercase}} <span *ngIf="item== 'username'">*</span> : </strong>
                        <!-- item = 'username'? 'USERNAME*': item | uppercase -->
                    </div>
                    <div fxFlex="50">
                        <mat-form-field fxFlex="grow" appearance="outline">
                            <mat-select [(ngModel)]="connector.mappingSchema[item]"
                                [ngModelOptions]="{standalone: true}">
                                <mat-option value="">Select
                                </mat-option>
                                <mat-option [value]="item" *ngFor="let item of mappingKeys?.connector_keys">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" *ngFor="let field of customFields; let i = index">
                    <div fxLayout="row" fxLayoutAlign="center stretch">
                        <div fxFlex="40">
                            <mat-form-field class="example-full-width" appearance="outline" *ngIf="field?.customField">
                                <mat-label> {{field.value}} </mat-label>
                                <input type="input" matInput [(ngModel)]="field.name" [value]="field.value"
                                    [ngModelOptions]="{standalone: true}" [disabled]="field.disable">
                                <mat-icon *ngIf="!field.disable" matSuffix
                                    (click)="updateName(field, field.name)">check</mat-icon>
                                <mat-icon *ngIf="field.disable" matSuffix (click)="Disabled(field)">mode_edit
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50">
                            <mat-form-field fxFlex="grow" appearance="outline">
                                <mat-select [(ngModel)]="field.selectedValue" [ngModelOptions]="{standalone: true}">
                                    <mat-option value="">
                                        Select
                                    </mat-option>
                                    <mat-option [value]="item" *ngFor="let item of mappingKeys?.connector_keys">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions fxLayoutAlign="end " *ngIf="!customSelected">
    <button *ngIf="pageType == 'edit' && connector.resourceType?.toLowerCase() == 'salesforce'" mat-raised-button color ="default" (click)="testSaleforceConnection()">Test Saleforce Connection</button>
    <button mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
    <button *ngIf="saleForce" [disabled]="saleForce && connector.apiUrl == ''" mat-raised-button color="primary" (click)="EnableDisableConnection()">{{pageType ==
        'edit'? 'Save Settings'
        :'Enable'}}</button>
</div>